import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import WelcomeScreen from './screens/WelcomeScreen';
import ZodiacScreen from './screens/ZodiacScreen';
import ColorScreen from './screens/ColorScreen';
import NumberScreen from './screens/NumberScreen';
import LandingScreen from './screens/LandingScreen';
import AuthContainer from './screens/AuthContainer';
import AccountScreen from './screens/AccountScreen';
import GameSelectionScreen from './screens/GameSelectionScreen';
import TransactionsScreen from './screens/TransactionsScreen';
import Home from './screens/admin/Home';
import Login from './screens/admin/Login';
import AboutUsScreen from './screens/AboutUsScreen';
import ContactUsScreen from './screens/ContactUsScreen';
import RefundScreen from './screens/RefundScreen';
import PrivacyPolicyScreen from './screens/PrivacyPolicyScreen';
import TermsScreen from './screens/TermsScreen';
import DepositScreen from './screens/DepositScreen';

function App() {
  const [status, setStatus] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const loggedInStatus = localStorage.getItem('isLoggedInAdmin');
    if (loggedInStatus === 'true') {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogin = () => {
    localStorage.setItem('isLoggedInAdmin', 'true');
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('isLoggedInAdmin');
    setIsLoggedIn(false);
  };

  return (<Router>
    <Routes>
      <Route path="/" element={<LandingScreen />} />
      <Route path="/deposit" element={<DepositScreen />} />
      <Route path="/about-us" element={<AboutUsScreen />} />
      <Route path="/contact-us" element={<ContactUsScreen />} />
      <Route path="/refund-policy" element={<RefundScreen />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyScreen />} />
      <Route path="/terms-and-conditions" element={<TermsScreen />} />
      <Route path="/auth" element={<AuthContainer />} />
      <Route path="/account" element={<AccountScreen />} />
      <Route path="/welcome" element={<WelcomeScreen />} />
      <Route path="/game-selection" element={<GameSelectionScreen />} />
      <Route path="/zodiac" element={<ZodiacScreen />} />
      <Route path="/color" element={<ColorScreen />} />
      <Route path="/number" element={<NumberScreen />} />
      <Route path="/transactions" element={<TransactionsScreen />} />
      <Route path="/admin" element={isLoggedIn ? <Home onLogout={handleLogout} /> : <Login onLogin={handleLogin} />} />
    </Routes>
  </Router>);
  //
  // return (<h5>Server is upgrading, please check back later!</h5>)
}

export default App;